<template>
    <div class="con-wrap">
        <CarrotTitle :title="add.info.ename+'('+add.info.kname+') MCR 등록'">
            - 종합 Comment, Guide & Check Point, 역량리뷰 필수 입력사항입니다.<br>
            - 팀원의 평소 업무 중 행동을 떠올리며 종합 Comment, Guide & Check Point, 역량리뷰를 작성해 주세요.<br>
            - 월 1회, 연 최소4번~ 최대12번 작성 가능합니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-col">
                        <colgroup>
                            <col width="150">
                            <col width="150">
                            <col width="*">
                            <col width="*">
                            <col width="110">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>본부</th>
                                <th>팀</th>
                                <th>팀원</th>
                                <th>직급</th>
                                <th>입사일</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ add.info.office_name }}</td>
                                <td>{{ add.info.team_name }}</td>
                                <td>{{ add.info.ename }}({{ add.info.kname }})</td>
                                <td>{{ add.info.position_name }}</td>
                                <td>{{ add.info.joindate }}</td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <div class="mt-50 mb-20">
                        <select name="syear" id="syear" class="w-100px" v-model="add.syear" @change="add.makeSearchMonths">
                            <option :value="v" v-for="(v, k) in add.search_years" :key="k">{{ v }}</option>
                        </select>
                        <select name="smonth" id="smonth" class="w-100px ml-5" v-model="add.smonth" @change="add.doSearch(0)">
                            <option value="">선택</option>
                            <option :value="v" v-for="(v, k) in add.search_months" :key="k">{{ v }}</option>
                        </select>
                        <div class="clear"></div>
                    </div>

                    <table class="table-row">
                        <colgroup>
                            <col width="100">
                            <col width="120">
                            <col width="*">
                            <col width="300">
                        </colgroup>
                        <tbody v-if="add.idx>0||add.isLeader==false">
                            <!-- 피드백-Staff 문항 -->
                            <tr>
                                <th rowspan="11">역량<br>리뷰</th>
                                <th>요소</th>
                                <th>내용</th>
                                <th>
                                    <div class="dp-table w-225px">
                                        <img class="float-left ml-15" src="@/assets/img/db_relation_04.png" alt="낮은평가">
                                        <span>점수</span>
                                        <img class="float-right" src="@/assets/img/db_relation_01.png" alt="높은평가">
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th rowspan="3">업무열정</th>
                                <td>
                                    평소 일을 더 잘하고, 상황을 개선하기 위한 방법을 우선적으로 찾는다.
                                </td>
                                <td>
                                    <label v-for="(p, i) in 5" :key="i"><input type="radio" :value="p" v-model="add.competency.energetic[0]"><span class="ml-5 mr-20">&nbsp;{{ p }}</span></label> 
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    직무에 대한 열정과 열심히, 활기차게 일하려는 의지를 꾸준하게 보여준다.
                                </td>
                                <td>
                                    <label v-for="(p, i) in 5" :key="i"><input type="radio" :value="p" v-model="add.competency.energetic[1]"><span class="ml-5 mr-20">&nbsp;{{ p }}</span></label> 
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    내가 요구하는 것을 뛰어넘고, 지시를 내리기 전에 행동하려는 의지를 보여준다.
                                </td>
                                <td>
                                    <label v-for="(p, i) in 5" :key="i"><input type="radio" :value="p" v-model="add.competency.energetic[2]"><span class="ml-5 mr-20">&nbsp;{{ p }}</span></label> 
                                </td>
                            </tr>
                            <tr>
                                <th rowspan="3">업무내용</th>
                                <td>
                                    전체와 부분, 인과관계를 파악할 줄 알고, 우선 순위 설정, 스케쥴링에 능숙하다.
                                </td>
                                <td>
                                    <label v-for="(p, i) in 5" :key="i"><input type="radio" :value="p" v-model="add.competency.management[0]"><span class="ml-5 mr-20">&nbsp;{{ p }}</span></label> 
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    항상 제시간 안에 결과물을 제출한다.
                                </td>
                                <td>
                                    <label v-for="(p, i) in 5" :key="i"><input type="radio" :value="p" v-model="add.competency.management[1]"><span class="ml-5 mr-20">&nbsp;{{ p }}</span></label> 
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    업무 결과물의 퀄리티 부분에서 절대 나를 실망시키지 않는다.
                                </td>
                                <td>
                                    <label v-for="(p, i) in 5" :key="i"><input type="radio" :value="p" v-model="add.competency.management[2]"><span class="ml-5 mr-20">&nbsp;{{ p }}</span></label> 
                                </td>
                            </tr>
                            <tr>
                                <th rowspan="3">업무태도</th>
                                <td>
                                    업무 습관(지각, 휴식 시간 등)이 모범적이다.
                                </td>
                                <td>
                                    <label v-for="(p, i) in 5" :key="i"><input type="radio" :value="p" v-model="add.competency.attitude[0]"><span class="ml-5 mr-20">&nbsp;{{ p }}</span></label> 
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    내가 자리에 있고 없는 것과 상관없이, 꾸준히 성실하게 일할 것이다.
                                </td>
                                <td>
                                    <label v-for="(p, i) in 5" :key="i"><input type="radio" :value="p" v-model="add.competency.attitude[1]"><span class="ml-5 mr-20">&nbsp;{{ p }}</span></label> 
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    업무 진행 현황을 지속적으로 확인할 필요가 없다.
                                </td>
                                <td>
                                    <label v-for="(p, i) in 5" :key="i"><input type="radio" :value="p" v-model="add.competency.attitude[2]"><span class="ml-5 mr-20">&nbsp;{{ p }}</span></label> 
                                </td>
                            </tr>
                            <tr>
                                <th>동료관계</th>
                                <td>
                                    주변 동료들과 잘 협력한다.
                                </td>
                                <td>
                                    <label v-for="(p, i) in 5" :key="i"><input type="radio" :value="p" v-model="add.competency.communion[0]"><span class="ml-5 mr-20">&nbsp;{{ p }}</span></label> 
                                </td>
                            </tr>
                            <!-- //피드백-Staff 문항 -->
                        </tbody>
                        <tbody v-if="add.isLeader==true">
                            <!-- 피드백-리드(센터장) 문항 -->
                            <tr>
                                <th rowspan="7">역량<br>리뷰</th>
                                <th>요소</th>
                                <th>내용</th>
                                <th>
                                    <div class="dp-table w-225px">
                                        <img class="float-left ml-15" src="@/assets/img/db_relation_04.png" alt="낮은평가">
                                        <span>점수</span>
                                        <img class="float-right" src="@/assets/img/db_relation_01.png" alt="높은평가">
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th rowspan="6">리더십</th>
                                <td>
                                    팀원이 성과를 개선하는 데 도움이 되는 실행 가능한 피드백을 제공한다.
                                </td>
                                <td>
                                    <label v-for="(p, i) in 5" :key="i"><input type="radio" :value="p" v-model="add.competency.leadership[0]"><span class="ml-5 mr-20">&nbsp;{{ p }}</span></label> 
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    리드가 개입하지 않아도 되는 문제에 시시콜콜 간섭하지 않고, 필요한 상황에만 적절하게 개입한다.
                                </td>
                                <td>
                                    <label v-for="(p, i) in 5" :key="i"><input type="radio" :value="p" v-model="add.competency.leadership[1]"><span class="ml-5 mr-20">&nbsp;{{ p }}</span></label> 
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    팀원들이 팀에 우선적으로 중요한 일에 집중하도록 한다. 
                                </td>
                                <td>
                                    <label v-for="(p, i) in 5" :key="i"><input type="radio" :value="p" v-model="add.competency.leadership[2]"><span class="ml-5 mr-20">&nbsp;{{ p }}</span></label> 
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    팀원들을 하나의 인격체로 대하며 배려한다. 
                                </td>
                                <td>
                                    <label v-for="(p, i) in 5" :key="i"><input type="radio" :value="p" v-model="add.competency.leadership[3]"><span class="ml-5 mr-20">&nbsp;{{ p }}</span></label> 
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    팀원들과 소통하면서 팀의 목표를 정확하게 제시한다. 
                                </td>
                                <td>
                                    <label v-for="(p, i) in 5" :key="i"><input type="radio" :value="p" v-model="add.competency.leadership[4]"><span class="ml-5 mr-20">&nbsp;{{ p }}</span></label> 
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    팀원들의 문제를 해결하기 위해 효과적인 방법을 제공한다.
                                </td>
                                <td>
                                    <label v-for="(p, i) in 5" :key="i"><input type="radio" :value="p" v-model="add.competency.leadership[5]"><span class="ml-5 mr-20">&nbsp;{{ p }}</span></label> 
                                </td>
                            </tr>
                            <!-- //피드백-리드(센터장) 문항 -->
                        </tbody>
                    </table>
                    <table class="table-row mt-10">
                        <colgroup>
                            <col width="220">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>종합 Comment</th>
                                <td>
                                    <textarea class="w-100per h-100px" v-model.trim="add.comment"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th>Guide & Check Point</th>
                                <td>
                                    <textarea class="w-100per h-100px" v-model.trim="add.guidencheck"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>   
                    <button class="btn-default float-left mt-30" @click="add.goList">목록</button>

                    <div v-if="add.state!='Y'">
                        <button class="btn-default float-right mt-30 ml-20" @click="add.doSubmit">제출</button>
                        <button class="btn-default float-right mt-30" @click="add.tempSave">임시저장</button>
                    </div>
                    <div class="clear"></div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'


export default {
    layout:"myPIMS",
    components: {
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast  = useToast();

        const add = reactive({
            isLoaded : false,

            search_years  : [],
            search_months : [],

            idx    : 0,
            idx_hq : 0,
            syear  : "",
            smonth : "",
            info   : {},

            isLeader   : false,
            competency : {
                energetic  : [],
                management : [],
                attitude   : [],
                communion  : [],
                leadership : []
            },
            comment     : "",
            guidencheck : "",

            state : '',

            tempSave : () => {
                let params = {
                    idx         : add.idx,
                    idx_hq      : add.idx_hq,
                    syear       : add.syear,
                    smonth      : add.smonth,
                    competency  : add.competency,
                    comment     : add.comment,
                    guidencheck : add.guidencheck,
                    state       : "T"
                };

                axios.post('/rest/mypims/setMCR', params).then((res) => {
                    if( res.data.err == 0 ){
                        toast.success("임시저장 하였습니다.");
                    } else if( res.data.err == 401 ){
                        toast.error(res.data.err_msg);
                    }
                });
            },

            doSubmit : () => {
                let params = {
                    idx         : add.idx,
                    idx_hq      : add.idx_hq,
                    syear       : add.syear,
                    smonth      : add.smonth,
                    competency  : add.competency,
                    comment     : add.comment,
                    guidencheck : add.guidencheck,
                    state       : "Y"
                };

                if( params.competency.energetic.length <= 0 ){
                    toast.error('업무열정 리뷰를 선택하세요.');
                    return;
                } else {
                    for(let i=0; i<3; i++){
                        if( !params.competency.energetic[i] ){
                            toast.error('업무열정 리뷰를 선택하세요.');
                            return;
                        }
                    }
                }
                if( params.competency.management.length <= 0 ){
                    toast.error('업무내용 리뷰를 선택하세요.');
                    return;
                } else {
                    for(let i=0; i<3; i++){
                        if( !params.competency.management[i] ){
                            toast.error('업무내용 리뷰를 선택하세요.');
                            return;
                        }
                    }
                }
                if( params.competency.attitude.length <= 0 ){
                    toast.error('업무태도 리뷰를 선택하세요.');
                    return;
                } else {
                    for(let i=0; i<3; i++){
                        if( !params.competency.attitude[i] ){
                            toast.error('업무태도 리뷰를 선택하세요.');
                            return;
                        }
                    }
                }
                if( params.competency.communion.length <= 0 ){
                    toast.error('동료관계 리뷰를 선택하세요.');
                    return;
                }
                if( add.isLeader == true && params.competency.leadership.length <= 0 ){
                    toast.error('리더십 리뷰를 선택하세요.');
                    return;
                }
                if( !params.comment ){
                    toast.error('종합 Comment를 입력하세요.');
                    return;
                }
                if( !params.guidencheck ){
                    toast.error('Guide & Check Point를 입력하세요.');
                    return;
                }


                Swal.fire({
                    title : 'MCR 등록',
                    text  : '제출 후에는 수정이 불가합니다. 제출하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        axios.post('/rest/mypims/setMCR', params).then((res) => {
                            if( res.data.err == 0 ){
                                toast.success('제출하였습니다.');
                                router.back(-1);
                            } else {
                                if( res.data.err == 401 ){
                                    toast.error(res.data.err_msg);
                                } else {
                                    if(res.data.err_msg) toast.error(res.data.err_msg);
                                }
                            }
                        });
                    }
                })
            },

            doSearch : (idx) => {
                let params = {
                    idx    : idx,
                    idx_hq : add.idx_hq,
                    syear  : add.syear,
                    smonth : add.smonth
                };
                axios.get("/rest/mypims/getMCRInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        add.info = res.data.info;

                        add.idx = res.data.idx;

                        add.syear  = res.data.syear;

                        if( add.idx > 0 ){
                            router.replace(add.idx);
                            if( res.data.competency ) add.competency  = res.data.competency;
                            add.comment     = res.data.comment;
                            add.guidencheck = res.data.guidencheck;
                        } else {
                            router.replace('');
                            add.competency  = {
                                energetic  : [],
                                management : [],
                                attitude   : [],
                                communion  : [],
                                leadership : []
                            };
                            add.comment     = '';
                            add.guidencheck = '';
                        }

                        add.isLeader = false;
                        if( add.info.is_leader == 'Y' ){
                            add.isLeader = true;
                        }

                        add.makeSearchYears();
                        add.makeSearchMonths();

                        add.smonth = res.data.smonth;
                        add.state  = res.data.state;
                    } else {
                        toast.error('잘못된 접근입니다.');
                        router.push({
                            name : "myPIMS-MCRMCGView-idx",
                            params : { idx:add.idx_hq }
                        });
                    }

                    add.isLoaded = true;
                });
            },

            goList : () => {
                Swal.fire({
                    title : 'MCR 등록',
                    text  : '입력하신 내용이 사라집니다. 목록으로 이동하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        router.go(-1);
                        // router.push({
                        //     name : "myPIMS-MCRMCGView-idx",
                        //     params : { idx:add.idx_hq }
                        // });
                    }
                });
            },
            
            makeSearchYears: () => {
                let ey = add.info.joindate.substr(0, 4);
                let cd = new Date();
                let cy = cd.getFullYear();
                for(let i=cy; i>=ey; i--){
                    add.search_years.push(i);
                }
            },

            makeSearchMonths: () => {
                let cd = new Date();
                let cy = cd.getFullYear();
                add.search_months = [ '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12' ];
                if( Number(add.syear) >= cy ){
                    add.search_months.splice(cd.getMonth()+1, 12-cd.getMonth());
                }
                add.smonth = "";
            }
        });

        onMounted(() => {
            // Mounted
            add.idx    = route.params.idx?route.params.idx:"0";

            if( add.idx == 0 ){
                let td = new Date();
                add.syear  = td.getFullYear();
                add.smonth = td.getMonth()>8?(td.getMonth()+1):'0'+(td.getMonth()+1);
            }

            let ss = sessionStorage.getItem("MCRMCGView");
            if( ss ){
                ss = JSON.parse(ss);
                add.idx_hq = ss.idx;
            }

            add.doSearch(add.idx);
        });

        return {add};
    }
}
</script>

<style lang="scss" scoped>
</style>